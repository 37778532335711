import "@angular/localize/init";
import "zone.js";

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
import maplibregl from "maplibre-gl";
import "echarts/i18n/langFR";
// https://github.com/socketio/socket.io-client/issues/1166
(window as any).global = window;
maplibregl.prewarm();
